import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js"

export default function background() {
  const app = document.getElementById('drawCanvas');
  if (app) {
    const scene = new THREE.Scene();
    let lastScrollY = window.scrollY;
    let scrollDirection = 'down'; 
    let timer = 0;
    
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100);
    camera.position.z = 1.5;

    const renderer = new THREE.WebGLRenderer({alpha: true, antialias: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;  // 影の有効化
    app.appendChild(renderer.domElement);
    
    const ambientLight = new THREE.AmbientLight(0xf8f9fa, 1);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xf8f9fa, 0.1);
    directionalLight.position.set(0, 0, 10);
    directionalLight.castShadow = true;  // 影の有効化
    scene.add(directionalLight);

    const spotLight = new THREE.SpotLight(0xf8f9fa, 0.1);
    spotLight.position.set(0, 0, 10);
    spotLight.castShadow = true;  // 影の有効化
    scene.add(spotLight);

    // モデルの読み込み
    let model;
    const loader = new GLTFLoader();
    loader.load(
      '/images/model/object.glb',
      function (gltf) {
        model = gltf.scene;
        model.position.set(0, 0, 0);
        model.traverse(function (child) {
          if (child.isMesh) {
            child.castShadow = true;  // 影を投影
            child.receiveShadow = true;  // 影を受け取る
          }
        });

        // テクスチャを読み込む
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(
          '/images/model/texture.webp',
          function (texture) {
            model.traverse(function (child) {
              if (child.isMesh) {
                texture.minFilter = THREE.LinearMipmapLinearFilter;
                texture.magFilter = THREE.LinearMipmapLinearFilter;
                child.material = new THREE.MeshStandardMaterial({
                  map: texture,
                  metalness: 0.1, // 金属度
                  roughness: 0.1, // 粗さ
                  wireframe: false,
                });
              }
            });
            model.position.z = 0;
            model.rotation.x = -.1;
            model.rotation.z = -.1;
            scene.add(model);
            callback();
          },
          undefined,
          function (error) {
            console.error('An error occurred loading the texture:', error);
          }
        );
      },
      undefined,
      function (error) {
        console.error('An error occurred loading the GLTF model:', error);
      }
    );

    const clock = new THREE.Clock();
    window.addEventListener('resize', onWindowResize);

    // IS Function
    function callback() {
      animate();
    }

    function animate() {
      if (scrollDirection === 'down') {
        timer += 0.0025;
      } else {
        timer -= 0.0025;
      }
      firstAnimate(timer);
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }

    function getCSSVariable(variableName) {
      const rootStyle = getComputedStyle(document.documentElement);
      const valueWithUnit = rootStyle.getPropertyValue(variableName);
      const numericValue = parseFloat(valueWithUnit);
      return numericValue;
    }

    function firstAnimate(timer) {
      const screenValue = getCSSVariable('--screen') * 0.001;
      model.rotation.y = screenValue - timer;      
    }

    function onWindowResize() {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    }

    window.addEventListener('scroll', () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        scrollDirection = 'down';
      } else {
        scrollDirection = 'up';
      }
      lastScrollY = currentScrollY;
    });
  }
}
