import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as SmoothScroll from 'smooth-scroll'
import {Slideshow} from './class/Slideshow'
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

gsap.registerPlugin(ScrollTrigger);

export default function index() {
  const slides = document.getElementById('indexSlides')
  if(slides){
    const slideshow = new Slideshow(slides)
    document.querySelector('.fv__nav__item--prev').addEventListener('click', () => slideshow.prev())
    document.querySelector('.fv__nav__item--next').addEventListener('click', () => slideshow.next())
  }

  const indexSwiper = document.getElementById('indexSwiper')
  if(indexSwiper){
    const swiperParams = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      speed: 800,
      centeredSlides: false,
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-ctrl-next",
        prevEl: ".swiper-ctrl-prev",
      },
      pagination: {
        el: '.index-swiper-pagination',
        clickable: true,
      },
    }
    const swiper = new Swiper(indexSwiper, swiperParams)
  }

	let follower = document.getElementById('follower')
	let followItem = document.querySelectorAll(".clickable")
	if(follower){
		let
			fWidth = 80,
			delay = 8,
			mouseX = 0,
			mouseY = 0,
			posX = 0,
			posY = 0;

		TweenMax.to({}, .01, {
			repeat: -1,
			onRepeat: function() {
				posX += (mouseX - posX) / delay
				posY += (mouseY - posY) / delay
				TweenMax.set(follower, {
					css: {
						left: posX - (fWidth / 2),
						top: posY - (fWidth / 2)
					}
				});
			}
		});
		document.body.addEventListener("mousemove", function(e){
			mouseX = e.clientX
			mouseY = e.clientY
		});

		for (let i = 0; i < followItem.length; i++) {
			followItem[i].removeEventListener("mouseenter", {el: follower, handleEvent: mouseEnter})
			followItem[i].removeEventListener("mouseleave",{el: follower, handleEvent: mouseLeave})
			followItem[i].addEventListener("mouseenter",{el: follower, handleEvent: mouseEnter})
			followItem[i].addEventListener("mouseleave",{el: follower, handleEvent: mouseLeave})
		}
	}
}


function mouseEnter(){
	this.el.classList.add('active');
}
function mouseLeave(){
	this.el.classList.remove('active');
}