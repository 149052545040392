import { demo } from './components/demo'
import { createApp } from 'vue'

export default function usevue(){
  const app = createApp({
    components: {
      demo: demo()
    }
  })
  app.mount('#app')
}
