import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

export default function business() {

  const caseSwiper = document.getElementById('caseSwiper')
  if(caseSwiper){
    const swiperParams = {
      slidesPerView: 3,
      spaceBetween: 1,
      speed: 800,
      centeredSlides: false,
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    }
    const swiper = new Swiper(caseSwiper, swiperParams)
  }

}
