export function demo(){
  const temp = {
    data: function(){
      return {
          demo: 'hello world!'
      }
    },
    template: 
    `
      <div v-html="demo"></div>
    `
  }
  return temp;
};